import { isEmpty, merge, set, omit } from 'lodash';
import { CrudApiActions, FileApiActions } from "../api/endpoint";
import { handleLogin } from "../utility/Auth"
import { isBrowser } from "../components/common/ggfx-client/utility";

export const RegExpPattern = {
  'contact_info.telephone': /[^0-9+ ]+/ig,
  'contact_info.mobile_no': /[^0-9+ ]+/ig,
  'branch_info.branch_telephone': /[^0-9+ ]+/ig,
  'price': /[^0-9\\.]+/g,
  'max_price': /[^0-9\\.]+/g,
  'mobile': /[^0-9+ ]+/ig,
}

export const DefaultSelectValue = { value: null, label: "Select option" };

export const DefaultSelectCurrencyValue = {
  "name": "DOLLAR SIGN",
  "value": "\u0024",
  "label": "\u0024",
  "short_code": "USD"
};

export const HandleAttachmentDragDrop = async (event, additionalParams = {}, acceptedFiles) => {
  try {
    const FileForm = document.getElementById('upload-file').elements;
    const FormElementsLength = FileForm.length;
    let formDataFile = new FormData(),
      files = acceptedFiles;
    for (let index = 0; index < FormElementsLength; index++) {
      const element = FileForm[index];
      if (element.type !== 'file' && element.value !== undefined) {
        formDataFile.append(element.name, element.value)
      }
    }
    formDataFile.append('menu', additionalParams.parentPath);
    formDataFile.append('subPath', `${additionalParams.parentPath}${event.target.name}`);
    //upload a file in server but not in media library      
    let file_response = [];
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      formDataFile.delete('files');
      try {
        formDataFile.append('files', element);
        const { data } = await FileApiActions.upload(formDataFile);
        //Returns uploaded attachment url
        file_response.push({
          ...data,
          ...additionalParams?.action?.editItem,
          isUpdate: additionalParams?.action?.isUpdate,
          index: additionalParams?.action?.editItem?.index || data.index || index,
          order: additionalParams?.action?.editItem?.index || data.order || (index+1),          
          field: additionalParams?.action?.isUpdate ? additionalParams?.action?.editItem.field : additionalParams.uploadedDataFieldName
        });
      } catch (error) {
        console.log('HandleAttachment error', error, error.response)
      }
    }
    return Promise.resolve(file_response);
  } catch (error) {
    console.log('HandleAttachment error', error, event, additionalParams)
  }
}

//File attachment handling - single file only 
export const HandleAttachment = async (event, additionalParams = {}) => {
  try {
    const FileForm = document.getElementById('upload-file').elements;
    const FormElementsLength = FileForm.length;
    let formDataFile = new FormData(),
      files = [],
      fieldName = 'others';
    for (let index = 0; index < FormElementsLength; index++) {
      const element = FileForm[index];
      if (element.type === 'file') {
        formDataFile.append('field', element.name);
        files = element.files;
        fieldName = element.name;
      } else if (element.value !== undefined) {
        formDataFile.append(element.name, element.value)
      }
    }
    // /*Below one used to store the files in below subpath by default if want to change the you may pass "subPath"*/
    formDataFile.append('menu', additionalParams.parentPath);
    formDataFile.append('subPath', `${additionalParams.parentPath}${fieldName}`);
    Object.keys(additionalParams.fields || {})?.map(field => {
      formDataFile.append(field.key, field.value);
      return 1;
    })
    //upload a file in server but not in media library
    if (additionalParams.disableDefaultUpload)
      //Return formdata
      return Promise.resolve(formDataFile);
    else {
      let file_response = [];
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        formDataFile.delete('files');
        try {
          formDataFile.append('files', element);
          const { data } = await FileApiActions.upload(formDataFile);
          //Returns uploaded attachment url
          file_response.push({
            ...data,
            ...additionalParams?.action?.editItem,
            isUpdate: additionalParams?.action?.isUpdate,
            index: additionalParams?.action?.editItem?.index,
            field: additionalParams?.action?.isUpdate ? additionalParams?.action?.editItem.field : additionalParams.uploadedDataFieldName
          });
        } catch (error) {
          console.log('HandleAttachment error', error, error.response)
        }
      }
      return Promise.resolve(file_response);
    }
  } catch (error) {
    console.log('HandleAttachment error', error, event, additionalParams)
  }
}

export const HandleAttachmentField = async (event, additionalParams = {}) => {
  try {
    // console.log('HandleAttachment', event)
    const formData = new FormData();
    formData.append('files', event.target.files[0]);
    /*Below one used to store the files in below subpath by default if want to change the you may pass "subPath"*/
    formData.append('field', event.target.name);
    formData.append('menu', additionalParams.parentPath);
    formData.append('subPath', `${additionalParams.parentPath}/${event.target.name}`);
    additionalParams.fields?.map(field => {
      formData.append(field.key, field.value);
      return 1;
    })
    //upload a file in server but not in media library
    if (additionalParams.disableDefaultUpload)
      //Return formdata
      return Promise.resolve(formData);
    else {
      try {
        const { data } = await FileApiActions.upload(formData);
        //Returns uploaded attachment url
        return Promise.resolve(data);
      } catch (error) {
        console.log('HandleAttachment error', error, error.response)
      }
    }
  } catch (error) {
    console.log('HandleAttachment error', error, event, additionalParams)
  }
}

export const ClearAttachmentField = fieldId => 
  document.getElementById(fieldId).value = null;

//Field changes handling
export const HandleChange = (event, additionalParams = {}) => {
  //Below code used to replace the strings by empty based on given regexp
  if (RegExpPattern[event.target.name] && !additionalParams.excludeDefaultFilter) {
    const FilteredValue = event.target.value.toString().replace(RegExpPattern[event.target.name], "");
    event.target.value = FilteredValue;
  } else {
    const { target: { value } } = event;
    event.target.value = typeof value === 'string' ? event.target.value.trimStart() : event.target.value;
  }
}

//Form submission handling
export const HandleSubmit = async (event, additionalParams = {}) => {
  event.preventDefault();
  let jsonData = {};
  if (additionalParams.isSubmit) {
    jsonData = GetFormValuesByEvent(event);
    if (additionalParams.fields) {
      jsonData = merge(jsonData, additionalParams.fields);
    }
    jsonData.isSubmit = additionalParams.isSubmit || false;
  } else {
    jsonData = GetFormValuesById(additionalParams.formId, additionalParams.fields);
  }
  jsonData = omit(jsonData, ['undefined', '', 'null']);
  if ((!additionalParams.dontSendFormData) && (!isEmpty(jsonData))) {
    const ApiEndPoint = additionalParams.endPointName.split('.');
    let result = {};
    try {
      if (additionalParams.isAuth) {
        result = await handleLogin(additionalParams.endPointName, jsonData);
      } else {
        if (additionalParams.param) {
          result = await CrudApiActions[ApiEndPoint[1]](additionalParams.endPointName, additionalParams.param, jsonData);
        } else {
          result = await CrudApiActions[ApiEndPoint[1]](additionalParams.endPointName, jsonData);
          if (additionalParams.publish) {
            await Publish(`${additionalParams.parentPath}.publish`, result.data.id);
          }
        }
      }
      return Promise.resolve(result);
    } catch (error) {
      result = error;
      console.log('HandleSubmit error', error, error.response);
      return Promise.reject(result);
    }
  }
}

export const CheckFormValidity = (event, additionalParams = {}) => {
  let err = {};
  if (isBrowser()) {
    const FormFields = additionalParams.isSubmit ? event.target
      : (additionalParams.isCustom ? event :
        document.getElementById(additionalParams.formId));
    Object.keys(FormFields).map(key => {
      if (FormFields[key] && FormFields[key].name && (FormFields[key].name !== 'submit')) {
        err = { ...err, ...CheckFieldValidityFunc(FormFields[key]) }
      }
      return 1;
    })
  }
  return err;
}

export const CheckFieldValidity = (event, isSelectBox = false) => {
  return CheckFieldValidityFunc(isSelectBox ? event : event.target);
}

export const GetAllItems = (endpoint, additionalParams = {}) => {
  return CrudApiActions.getAll(endpoint, additionalParams);
}

export const DeleteItem = (endpoint, param, additionalParams = {}) => {
  return CrudApiActions.delete(endpoint, param);
}

export const GetItemById = (endpoint, param, additionalParams = {}) => {
  return CrudApiActions.getById(endpoint, param);
}

export const Publish = (endpoint, param, additionalParams = {}) => {
  return CrudApiActions.publish(endpoint, param);
}

export const UnPublish = (endpoint, param, additionalParams = {}) => {
  return CrudApiActions.unpublish(endpoint, param);
}

export const GenerateRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const GetFormValuesByEvent = (event, excludeSet = false) => {
  const RawData = event.target;
  let jsonFormedData = {};
  //Form copy field values for json conversion
  Object.keys(RawData).map(key => {
    if (RawData[key].value) {
      if (!excludeSet) {
        //set function to set the values under an object which has . in field name
        //By default checkbox value should be true
        if ((RawData[key].type === 'checkbox') || (RawData[key].type === 'radio')) {
          if (RawData[key].checked) {
            set(jsonFormedData, RawData[key].name, RawData[key].value === 'true' ? true :
              (RawData[key].value === 'false' ? false : RawData[key].value));
          }
        } else {
          if(RawData[key].type === 'number') {
            set(jsonFormedData, RawData[key].name, parseInt(RawData[key].value));
          } else {
            set(jsonFormedData, RawData[key].name, RawData[key].value);
          }
        }
      } else {
        jsonFormedData[RawData[key].name] = RawData[key].value
      }
    } else {
      jsonFormedData[RawData[key].name] = (
        (RawData[key].type === 'text') ? ""
          : ((RawData[key].type === 'number') ? 0 : "")
      );
    }
    return 1;
  });
  return jsonFormedData;
}

export const GetFormValuesById = (formId, additionalParams = {}) => {
  const FormElements = isBrowser() ? document.getElementById(formId).elements : [],
    FormElementsLength = FormElements.length;
  let formValuesJson = {};
  for (let index = 0; index < FormElementsLength; index++) {
    const element = FormElements[index];
    if (element.value !== undefined) {
      set(formValuesJson, element.name, (element.type === 'number') ? parseInt(element.value) : element.value);
    }
  }
  return merge(formValuesJson, additionalParams);
}

export const ResetFormValuesById = (formId) => {
  if (isBrowser())
    document.getElementById(formId).reset();
  return 1;
}

const CheckFieldValidityFunc = ({ name, value, attributes, type }) => {
  let err = {};
  if (attributes?.getNamedItem('required') !== null) {
    if (!value) {
      err = {
        [name]: { hasError: true, type: 'required' },
      }
    } else {
      err = {
        [name]: { hasError: false, type: 'required' },
      }
    }
  }
  //handle other type of input fields like checkbox 
  if (!err[name]?.hasError) {
    if (value) {
      switch (type) {
        case 'text':
          //min length and max length 
          const ValueLength = value.length;
          if (attributes?.getNamedItem('minLength')) {
            if (ValueLength < parseInt(attributes?.getNamedItem('minLength'))) {
              err = {
                [name]: { hasError: true, type: 'minLength' },
              }
            } else {
              err = {
                [name]: { hasError: false, type: 'minLength' },
              }
            }
          }
          if (!err[name]?.['hasError']) {
            if (attributes?.getNamedItem('maxLength')) {
              if (ValueLength > parseInt(attributes?.getNamedItem('maxLength'))) {
                err = {
                  [name]: { hasError: true, type: 'maxLength' },

                }
              } else {
                err = {
                  [name]: { hasError: false, type: 'maxLength' },
                }
              }
            }
          }
          if (!err[name]?.['hasError']) {
            const RegExpPattern = attributes?.getNamedItem('pattern')?.value;
            if (RegExpPattern) {
              //Add '\' before the each \ in the give pattern string
              const isValid = new RegExp(RegExpPattern).test(value);
              err = {
                [name]: { hasError: !isValid, type: 'pattern' },

              }
            }
          }
          break;
        case 'number':
          // min and max             
          if (attributes?.getNamedItem('min')) {
            if (value < parseInt(attributes?.getNamedItem('min').value)) {
              err = {
                [name]: { hasError: true, type: 'min' },
              }
            } else {
              err = {
                [name]: { hasError: false, type: 'min' },
              }
            }
          }
          if (!err[name]?.['hasError']) {
            if (attributes?.getNamedItem('max')) {
              if (value > parseInt(attributes?.getNamedItem('max').value)) {
                err = {
                  [name]: { hasError: true, type: 'max' },
                }
              } else {
                err = {
                  [name]: { hasError: false, type: 'max' },
                }
              }
            }
          }
          break;
        default:
          break;
      }
    } else {
      err = {
        [name]: { hasError: false, type: 'reset' },
      }
    }
  }
  return err;
}
