import PropertyUploaderConfig from "../config/property-uploader";
import ProcessRequest from '../services/Axios';
import {isEmpty} from 'lodash';
import {getToken} from "../utility/Auth"

const STRAPI_URL = process.env.GATSBY_STRAPI_SRC,
  TOKEN = process.env.GATSBY_STRAPI_SRC_TOKEN,
  TOKEN_TYPE = 'Bearer';

export const CrudApiActions = {
  create: (endpointname, data)=> {
    return MakeRequest({
      url: FormUrl(endpointname),
      method: 'post',
      data: data
    })
  },

  edit: (endpointname, param, data)=>{
    return MakeRequest({
      url: `${FormUrl(endpointname)}/${param}`,
      method: 'put',
      data: data
    })
  },

  getById: (endpointname, param)=>{
    return MakeRequest({
      url: `${FormUrl(endpointname)}/${param}`,
      method: 'get'
    })
  },

  getAll: (endpointname, queryParam = {})=> {    
    if(queryParam.isGQL){
      return MakeRequest({
        ...FormGraphQL(endpointname, queryParam),
        method: 'post'
      })
    }
    return MakeRequest({
      url: `${FormUrl(endpointname)}${!isEmpty(queryParam.query) ? `?${queryParam.query}`: '?page=1&pageSize=10'}`,
      method: 'get'
    })
  },

  delete: (endpointname, param)=>{
    return MakeRequest({
      url: `${FormUrl(endpointname)}/${param}`,
      method: 'delete'
    })
  },

  publish: (endpointname, param)=>{
    return MakeRequest({
      url: `${FormUrl(endpointname)}/${param}/actions/publish`,
      method: 'post'
    })
  },

  unpublish: (endpointname, param)=>{
    return MakeRequest({
      url: `${FormUrl(endpointname)}/${param}/actions/unpublish`,
      method: 'post'
    })
  },
}

export const AuthApiActions = {
  authAction: (endpointname, data)=>{
    return MakeRequest({
      url: `${FormUrl(endpointname)}`,
      method: 'post',
      data: data
    }, true)
  },
  
  me: (endpointname, param)=>{
    return MakeRequest({
      url: `${PropertyUploaderConfig[endpointname]}/${param}`,
      method: 'delete'
    })
  }
}

export const FileApiActions = {
  upload: (data)=> {
    return MakeRequest({
      url: FormUrl('file.upload'),
      method: 'post',
      data: data
    })
  },

  delete: (data)=>{
    return MakeRequest({
      url: FormUrl('file.delete'),
      method: 'delete'      
    })
  },

  getUploadedItems: (query) => {
    return MakeRequest({
      url: FormUrl('file.get'),
      method: 'get'      
    })
  }
};

const FormUrl = (endpointname) => {
  try {
    const EndPoint = endpointname.split('.'),
      ActionUri = PropertyUploaderConfig[EndPoint[0]]['endpoint'][EndPoint[1]],
      ActionUriType = typeof ActionUri;
    if(ActionUriType === 'object'){      
      return `${(PropertyUploaderConfig[EndPoint[0]]['endpoint'][EndPoint[1]]['excludeApiPrefix']) ? ''
        : `${PropertyUploaderConfig.rootPath}/`}${(PropertyUploaderConfig[EndPoint[0]]['endpoint'][EndPoint[1]]['uri']) ? `${PropertyUploaderConfig[EndPoint[0]]['endpoint'][EndPoint[1]]['uri']}`: ""}${ActionUriType !== 'object' ? `/${ActionUri}`:''}`;
    }
    return `${(PropertyUploaderConfig[EndPoint[0]]['endpoint']['excludeApiPrefix']) ? ''
      : `${PropertyUploaderConfig.rootPath}/`}${(PropertyUploaderConfig[EndPoint[0]]['endpoint']['uri']) ? `${PropertyUploaderConfig[EndPoint[0]]['endpoint']['uri']}`: ""}${ActionUri? `/${ActionUri}`:''}`;
  } catch (error) {
    console.log('FormUrl', endpointname, error);
  }
}

const FormGraphQL = (endpointname, queryParam) => {
  return {
    url: `/graphql`,
    data: JSON.stringify({
      query:
        `query { ${FormUrl(endpointname)}(${queryParam.query || 'where: {_limit: -1}, publicationState: PREVIEW'}) { ${queryParam.fields || 'id'}} }`,
    })
  }
}

const MakeRequest = (options, isLogin =false)=>{
  return ProcessRequest({
    baseURL: STRAPI_URL,
    headers: {
      "Content-Type": "application/json",
      //TODO: Get the token from cookie for individuals
      'Authorization': `${TOKEN_TYPE} ${isLogin ? TOKEN: getToken()}`,
    },
    ...options
  })
}
