import React from "react";
import { get } from "lodash";
import NO_IMG from "../images/no-image.png";
import ZIP from "../images/zip-file.png";
import PDF from "../images/pdf-file.png";

const EuroCurrencyFormatter = (price = 0) => {
  return price ? price.toLocaleString('en-IE', {
    style: 'currency',
    currency: 'EUR',
  }) : '';
}

const PropertyUploaderConfig = {
  rootPath: "property-uploader/api",
  "building_managers": {
    "uri": "pu-building-manager",
    "delete": {
      "uri": "delete/1/property-uploader.pu-building-manager",
    }
  },
  "agents_custom": {
    endpoint: {
      "uri": "pu-agents",
      changesStatus: "approval",
      //Unique branches
      getAllBranches: "branch"
    },
  },
  "agents": {
    endpoint: {
      "uri": "content-manager/collection-types/plugins::users-permissions.user",
      excludeApiPrefix: true,
      create: "",
      getAll: "",
      edit: "",
      "delete": ""
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Agent Name',
          selector: row => row.nego_name,
          sortable: true,
          sortField: 'nego_name',
        },
        {
          name: 'Agency',
          selector: row => row.agency_name,
          sortable: true,
          sortField: 'agency_name'
        },
        {
          name: 'Branch',
          selector: row => get(row, 'branch_name'),
          sortable: true,
          sortField: 'branch_name'
        },
        {
          name: 'Email',
          selector: row => row.email,
          sortable: true,
          sortField: 'email'
        },
        {
          name: 'Contact Number',
          selector: row => get(row, 'contact_info.telephone') || get(row, 'contact_info.mobile_no'),
          sortable: true,
          //sortField: 'email'                
        },
        {
          name: 'Approval Status',
          //selector: row => row.approval_status,
          sortable: true,
          sortField: 'approval_status',
          cell: agent => (
            <>
              <div style={{ 'text-transform': 'capitalize' }}>
                {get(agent, 'approval_status')}
              </div>
            </>
          )
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true,
      excludePublishedStatus: true
    }
  },
  "child-properties": {
    endpoint: {
      "uri": "pu-property",
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Thumbnail',
          //selector: row => row.building_name,
          sortable: true,
          sortField: 'building_name',
          cell: property => (
            <>
              {/* <p>{JSON.stringify(media)}</p> */}
              <div className="building-wrapper py-3">
                {/* <img src={get(property, 'images.0.url')} width="200px" height="200px" className="img-fluid" /> */}
                {
                  get(property, 'images.0.url') ? <img src={get(property, 'images.0.url')} width="200px" height="200px" className="img-fluid" /> :
                    <img src={NO_IMG} width="200px" height="200px" className="img-fluid" />
                }
                <p>{get(property, 'building_name')}</p>
              </div>
            </>
          )
        },

        // {
        //     name: 'Number / Name',
        //     selector: row => row.building_name,
        //     sortable: true,
        //     sortField: 'building_name',                 
        // }, 
        // {
        //     name: 'Building',
        //     selector: row => row.parent_building,
        //     sortable: true,
        //     sortField: 'parent_building.building_name',  
        //     cell: building => (
        //         get(building, 'parent_building.building_name')
        //     )                 
        // }, 
        {
          name: 'Reference Number',
          selector: row => row.reference_number,
          sortable: true,
          sortField: 'reference_number',
        },
        // {
        //     name: 'Address',
        //     selector: row => row.parent_building,
        //     sortable: true,
        //     sortField: 'parent_building.address_line1',  
        //     cell: building => (
        //         get(building, 'parent_building.address_line1')
        //     )                 
        // }, 
        {
          name: 'Price',
          //selector: row => row.price,
          sortable: true,
          sortField: 'price',
          cell: property => (
            <>
              {`${EuroCurrencyFormatter(get(property, 'price'))}`}
            </>
          )
        },
        {
          name: 'Bedroom',
          selector: row => row.bedroom,
          sortable: true,
          sortField: 'bedroom'

        },
        {
          name: 'Building Manager',
          selector: row => row.building_manager,
          sortable: true,
          sortField: 'building_manager.name',
          cell: building_manager => (
            get(building_manager, 'building_manager.name')
          )
        },
      ],
      excludeAction: true,
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "properties": {
    endpoint: {
      "uri": "pu-property",
      "delete": {
        "uri": "delete/1/property-uploader.pu-property",
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Thumbnail',
          //selector: row => row.building_name,
          sortable: true,
          sortField: 'building_name',
          cell: property => (
            <>
              {/* <p>{JSON.stringify(media)}</p> */}
              <div className="building-wrapper py-3">
                {/* <img src={get(property, 'images.0.url')} width="200px" height="200px" className="img-fluid" /> */}
                {
                  get(property, 'images.0.url') ? <img src={get(property, 'images.0.url')} width="200px" height="200px" className="img-fluid" /> :
                    <img src={NO_IMG} width="200px" height="200px" className="img-fluid" />
                }
                <p>{get(property, 'building_name')}</p>
              </div>
            </>
          )
        },
        {
          name: 'Building',
          selector: row => row.parent_building,
          sortable: true,
          sortField: 'parent_building.building_name',
          cell: building => (
            get(building, 'parent_building.building_name')
          )
        },
        // {
        //     name: 'Address',
        //     selector: row => row.parent_building,
        //     sortable: true,
        //     sortField: 'parent_building',  
        //     cell: building => (
        //         get(building, 'parent_building.address_line1')
        //     )                 
        // }, 
        {
          name: 'Price',
          //selector: row => row.price,
          sortable: true,
          sortField: 'price',
          cell: property => (
            <>
              {`${get(property, 'currency.label')} ${get(property, 'price')}`}
            </>
          )

        },
        {
          name: 'Bedroom',
          selector: row => row.bedroom,
          sortable: true,
          sortField: 'bedroom'

        },
        // {
        //     name: 'Building Manager',
        //     //selector: row => row.building_manager,
        //     sortable: true,
        //     sortField: 'building_manager',  
        //     cell: building_manager => (
        //         get(building_manager, 'parent_building.building_manager.name')
        //     )                 
        // }, 
        {
          name: 'Property Type',
          selector: row => row.building,
          sortable: true,
          sortField: 'building'
        },

      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "commercial-properties": {
    endpoint: {
      "uri": "pu-property",
      // "uri": "content-manager/collection-types/application::property.property",
      // excludeApiPrefix: true,
      "delete": {
        "uri": "delete/1/property-uploader.pu-property",
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Building Name',
          selector: row => row.building_name,
          sortable: true,
          sortField: 'building_name',
        },
        {
          name: 'Property Type',
          selector: row => row.building,
          sortable: true,
          sortField: 'building',
        },
        {
          name: 'Enabled',
          sortable: true,
          sortField: 'is_enabled',
          cell: property => (
            <div className="form-check dashboard-checkbox d-flex justify-content-center">
              <input className="form-check-input" type="checkbox" value="" id="enabled"
                checked={property.is_enabled} disabled={true} />
            </div>
          )
        },
        {
          name: 'Show On Website',
          sortable: true,
          sortField: 'is_show_on_website',
          cell: property => (
            <div className="form-check dashboard-checkbox d-flex justify-content-center">
              <input className="form-check-input" type="checkbox" value="" id="enabled"
                checked={property.is_show_on_website} disabled={true} />
            </div>
          )
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "building-managers": {
    endpoint: {
      "uri": "pu-building-manager",
      //below options to add more paths if required
      "create": "",
      "edit": "",
      "get": "",
      "delete": {
        "uri": "delete/1/property-uploader.pu-building-manager",
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Manager Name',
          //selector: row => row.name,
          sortable: true,
          sortField: 'name',
          cell: manager => (
            <div className="building-wrapper py-3">
              {/* <img src={get(manager, 'picture.url')} width="200px" height="200px" className="img-fluid" />
                            <p>{manager.name}</p> */}
              {
                (get(manager, 'picture.url') ?
                  <img src={get(manager, 'picture.url')} width="200px" height="200px" className="img-fluid" /> :
                  <img src={NO_IMG} width="200px" height="200px" className="img-fluid" />
                )

              }
              <p>{manager.name}</p>
            </div>
          )
        },
        {
          name: 'Designation',
          selector: row => row.designation,
          sortable: true,
          sortField: 'designation',
        },
        {
          name: 'Mobile',
          selector: row => row.mobile,
          sortable: true,
          sortField: 'mobile'
        },
        {
          name: 'Email Address',
          selector: row => row.email_address,
          sortable: true,
          sortField: 'email_address'
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "regions": {
    endpoint: {
      "uri": "pu-region",
      "delete": {
        "uri": "delete/1/property-uploader.pu-region",
      }
    },
    table: {
      columns: [
        {
          name: 'Region Name',
          selector: row => row.name,
          sortable: true,
          sortField: 'name',
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "facilities": {
    endpoint: {
      "uri": "pu-facility",
      "delete": {
        "uri": "delete/1/property-uploader.pu-facility",
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Facility',
          selector: row => row.facility,
          sortable: true,
          sortField: 'facility',
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true,
      excludePublishedStatus: true
    }
  },
  "building-types": {
    endpoint: {
      "uri": "pu-building-type",
      //below options to add more paths if required
      "create": "",
      "edit": "",
      "getAll": "",
      "getById": "",
      "delete": {
        "uri": "delete/1/property-uploader.pu-building-type",
      }
    },
    table: {
      columns: [
        {
          name: 'Building Type',
          selector: row => row.name,
          sortable: true,
          sortField: 'name',
        },
      ],
      excludeTimeStamps: true,
      excludeUser: true,
      //excludeAction: true
    }
  },
  "buildings": {
    endpoint: {
      "uri": "pu-building",
      "delete": {
        "uri": "delete/1/property-uploader.pu-building",
      }
    },
    table: {
      default: {
        sortField: 'updatedAt',
        sortOrder: 'desc'
      },
      columns: [
        {
          name: 'Building Name',
          //selector: row => row.building_name,
          sortable: true,
          sortField: 'building_name',
          width: '200px',
          cell: building => (
            // <>
            //     {/* <p>{JSON.stringify(media)}</p> */}
            //     <div className="building-wrapper py-3">                               
            //         <img src={get(building, 'images.0.url')} width="200px" height="200px" className="img-fluid" />
            //         <p>{get(building, 'building_name')}</p>
            //     </div>
            // </>
            <>
              {/* <p>{JSON.stringify(media)}</p> */}
              <div className="building-wrapper py-3">
                {
                  get(building, 'images.0.url') ? <img src={get(building, 'images.0.url')} width="200px" height="200px" className="img-fluid" /> :
                    <img src={NO_IMG} width="200px" height="200px" className="img-fluid" />
                }
                <p>{get(building, 'building_name')}</p>
              </div>
            </>
          )
        },
        {
          name: 'Offered',
          selector: row => get(row, 'status'),
          sortable: true,
          sortField: 'status',
        },
        {
          name: 'Main Region',
          selector: row => get(row, 'main_region.name'),
          sortable: true,
          sortField: 'main_region',
        },
        {
          name: 'Building Manager Name',
          selector: row => get(row, 'main_building_manager.name'),
          sortable: true,
          sortField: 'main_building_manager.name',
        },
        {
          name: 'Available Properties',
          selector: row => row?.child_properties?.count,
          sortable: true,
          sortField: 'child_properties',
        },
      ],
      excludeUser: true,
      excludeTimeStamps: true
    }
  },
  "settings": {
    endpoint: {
      "uri": "pu-setting",
    },
  },
  "file": {
    endpoint: {
      "uri": "pu-file",
      "upload": "upload",
      "delete": "",
      "getById": "",
      "getItems": ""
    },
    table: {
      columns: [
        {
          name: 'Thumbnail',
          sortable: true,
          // sortField: 'url',                   
          cell: media => (
            <>
              {/* <p>{JSON.stringify(media)}</p> */}
              <img src={
                (media.ext === '.zip') ? ZIP :
                  ((media.ext === '.pdf') ? PDF :
                    (get(media, 'url') ? get(media, 'url') :
                      NO_IMG))
              } style={{ width: "150px", height: "100px", marginBottom: "5px", marginTop: "5px" }} />
            </>
          ),
          // width: "20%"             
        },
        // {
        //     name: 'Type',                    
        //     sortable: true, 
        //     sortField: 'ext',                   
        //     cell: media => (
        //         <>
        //              <p>{media.ext}</p>
        //         </>
        //     )               
        // },
        {
          name: 'Category',
          sortable: true,
          // sortField: 'category',                   
          cell: media => (
            <>
              {media.category}
            </>
          ),
          // width: "15%"             
        },
        {
          name: 'Caption',
          sortable: true,
          // sortField: 'caption',                   
          cell: media => (
            <>
              {media.caption}
            </>
          ),
          // width: "15%"              
        },
      ],
      excludeUser: true
    }
  },
  "auth": {
    endpoint: {
      excludeApiPrefix: true,
      excludeAuthHeader: true,
      "uri": "admin",
      "login": "login",
      "forgot-password": "forgot-password",
      "reset-password": "reset-password"
    }
  },
  "offices": {
    endpoint: {
      excludeApiPrefix: true,
      "uri": "offices",
      "getAll": ""
    }
  },
  "teams": {
    endpoint: {
      excludeApiPrefix: true,
      "uri": "teams",
      "getAll": ""
    }
  },
};

export default PropertyUploaderConfig;